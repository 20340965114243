label:focus > .radio-button,
label > input:focus + .radio-button {
  border-color: var(--field-focus-border-color);
  box-shadow: var(--focused-box-shadow);
}

label > input:checked + .radio-button {
  border-color: var(--field-focus-border-color);

  &::after {
    transform: scale(1);
  }
}

label > input.touched:invalid + .radio-button {
  border-color: var(--field-invalid-border-color);
}

label > input:disabled + .radio-button {
  background-color: var(--field-disabled-background-color);
  border-color: var(--field-disabled-border-color);
  color: var(--field-addon-disabled-color);
}
/* TICK */
.radio-button {
  --size: 1em;
  --inner-size: 0.625em;

  align-items: center;
  background-color: var(--field-background-color);
  border: var(--checkbox-border-width) solid var(--field-border-color);
  border-radius: 50%;
  box-shadow: var(--focusable-box-shadow);
  color: var(--color-brand);
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--size);
  justify-content: center;
  line-height: 1;
  position: relative;
  top: calc(0.5em - 0.5ch);
  transition: var(--field-transition);
  width: var(--size);

  &::after {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    display: block;
    height: var(--inner-size);
    overflow: hidden;
    position: absolute;
    transform: scale(0);
    transition: transform 0.2s ease;
    width: var(--inner-size);
  }
}
