.field.label > .label-text,
.label-text {
  align-items: center;
  color: var(--field-label-color);
  display: flex;
  font: var(--field-label-font);
  margin-bottom: 0.5rem;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: normal;
}

.input[required]:not([disabled]):not([readonly]) ~ .label-text::after,
.label-text.required::after {
  align-self: flex-start;
  content: '*';
  display: inline;
  font-weight: var(--bold);
  margin-left: 0.25em;
}
