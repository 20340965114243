.field.secret-field {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-self: stretch;
  position: relative;

  & > input {
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: -2rem;
    max-width: 100%;
    min-width: 0;
    width: 100%;
  }

  .secret-field-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    order: 3;
    position: relative;
    width: 2rem;

    & > svg {
      display: block;
      fill: currentColor;
      height: 1rem;
      width: 1rem;
    }

    &:focus > svg {
      color: var(--field-addon-focus-color);
    }
  }
}
