.radio-button-group {
  appearance: none;
  border: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  legend {
    color: var(--field-label-color);
    display: block;
    float: left;
    font-family: inherit;
    font-size: var(--field-label-font-size);
    line-height: var(--field-label-line-height);
    margin-bottom: var(--gap-small);
  }

  &.horizontal > .input {
    display: flex;
    flex-direction: row;

    label {
      align-items: baseline;
      margin-right: var(--gap);
    }
  }
}
