.password-message {
  align-items: center;
  display: flex;
  justify-content: center;

  svg {
    display: flex;
    fill: currentColor;
    flex-grow: 0;
    flex-shrink: 0;
    height: 1em;
    margin-right: 0.25rem;
    width: 1em;
  }
}
