.field.label.select-container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 0;
  overflow: visible;
  position: relative;

  & > .label-text {
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: auto;
    order: 1;
  }

  & > select {
    appearance: none;
    background-color: var(--field-background-color);
    background-image: none;
    border-color: var(--field-border-color);
    border-radius: var(--field-border-radius);
    border-style: solid;
    border-width: var(--field-border-width);
    box-shadow: var(--focusable-box-shadow);
    box-sizing: border-box;
    color: var(--field-text-color);
    cursor: pointer;
    flex-grow: 1;
    flex-shrink: 1;
    height: var(--field-height);
    margin-right: -1rem;
    max-width: 100%;
    min-width: 0;
    order: 2;
    /* overflow: hidden; */
    padding: 0 var(--field-addon-width) 0 var(--field-padding-horizontal);
    transition: var(--field-transition);
    width: 100%;

    &:hover {
      border-color: var(--field-hover-border-color);
      color: var(--field-hover-text-color);
    }

    &.touched:invalid {
      border-color: var(--field-invalid-border-color);
    }

    &:focus {
      border-color: var(--field-focus-border-color);
      box-shadow: var(--focused-box-shadow);
      outline: none;

      & ~ .select-icon {
        color: var(--field-addon-focus-color);
      }
    }

    &:disabled {
      background-color: var(--field-disabled-background-color);
      border-color: var(--field-disabled-border-color);
      color: var(--field-disabled-color);
      cursor: default;

      & ~ svg {
        color: var(--field-addon-disabled-color);
      }
    }

    &.read-only {
      background-color: var(--field-read-only-background-color);
      border-color: var(--field-read-only-border-color);
      color: var(--field-read-only-color);
      cursor: default;

      & ~ svg {
        color: var(--field-addon-disabled-color);
      }
    }
  }

  & > .select-icon {
    color: var(--field-addon-color);
    fill: currentColor;
    left: -1rem;
    margin: calc((var(--field-height) - 1rem) / 2) 0;
    order: 3;
    pointer-events: none;
    position: relative;
    width: 1rem;
  }

  & > select[readonly] + .select-icon {
    display: none;
  }
}
