.phone-field {
  &:hover {
    & > .react-tel-input > input,
    & > .react-tel-input > .flag-dropdown {
      border-color: var(--field-hover-border-color);
      color: var(--field-hover-text-color);
    }
  }

  & > .react-tel-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    order: 2;
    position: relative;
    width: 100%;

    & > .flag-dropdown {
      background-color: var(--field-background-color);
      border-radius: var(--field-border-radius);
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-width: var(--field-border-width);
      border-right-width: 1px;
      transition: border-color 0.15s ease-in-out;
      width: var(--field-addon-width);
      z-index: 1;

      .selected-flag {
        width: var(--field-addon-width);

        .arrow {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid var(--field-addon-color);

          &.up {
            border-bottom: 6px solid var(--field-addon-color);
            border-top: none;
          }
        }
      }

      &.read-only {
        background-color: var(--field-read-only-background-color);
        border-color: var(--field-read-only-border-color);
        color: var(--field-read-only-color);
        cursor: default;
      }

      &.disabled {
        background-color: var(--field-disabled-background-color);
        border-color: var(--field-disabled-border-color);
        color: var(--field-disabled-color);
        cursor: default;
      }

      &.touched.invalid,
      &.touched:invalid {
        border-color: var(--field-invalid-border-color);
      }

      &:focus,
      &:hover {
        .selected-flag {
          background-color: transparent;
        }
      }
    }

    & > .validation-message {
      order: 3;
    }

    & > input {
      background-color: var(--field-background-color);
      border-color: var(--field-border-color);
      border-radius: var(--field-border-radius);
      border-style: solid;
      border-width: var(--field-border-width);
      box-shadow: var(--focusable-box-shadow);
      box-sizing: border-box;
      color: var(--field-text-color);
      flex-grow: 1;
      flex-shrink: 0;
      height: var(--field-height);
      line-height: var(--field-line-height);
      max-height: var(--field-height);
      min-height: var(--field-height);
      order: 2;
      padding: var(--field-padding);
      padding-left: calc(var(--field-addon-width) + var(--field-padding-horizontal));
      transition: all 0.15s ease-in-out;
      width: 100%;

      &:hover {
        border-color: var(--field-hover-border-color);
        color: var(--field-hover-text-color);
      }

      &:active,
      &:focus:not(:read-only) {
        border-color: var(--field-focus-border-color);
        box-shadow: var(--focused-box-shadow);
        outline: none;
      }

      &:read-only {
        color: var(--field-read-only-color);
        cursor: default;
        outline: none;
        pointer-events: none;
      }

      &.touched.invalid,
      &.touched:invalid {
        border-color: var(--field-invalid-border-color);
      }

      &[required] ~ .label-text::after {
        /* content: '*'; */
      }

      &[readonly] {
        background-color: var(--field-read-only-background-color);
        border-color: var(--field-read-only-border-color);
        color: var(--field-read-only-color);
        cursor: default;
        pointer-events: none;

        & ~ .label-text {
          color: var(--field-read-only-color);
        }
      }

      &:disabled {
        background-color: var(--field-disabled-background-color);
        border-color: var(--field-disabled-border-color);
        color: var(--field-disabled-color);
        cursor: default;
        pointer-events: none;

        & ~ .flag-dropdown {
          border-color: var(--field-disabled-border-color);
        }

        & ~ .label-text {
          color: var(--field-disabled-color);
        }
      }
    }
  }
}
